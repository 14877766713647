import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d270e4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: $props.url,
    class: "social"
  }, [
    _createElementVNode("img", {
      src: '/icons/' + $props.icon + '.svg',
      alt: $props.alt,
      class: "socialicon"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}