import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13ae5114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navcontainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavItem = _resolveComponent("NavItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavItem, {
      name: "Home",
      url: "home"
    }),
    _createVNode(_component_NavItem, {
      name: "Goals",
      url: "goals"
    }),
    _createVNode(_component_NavItem, {
      name: "Projects",
      url: "projects"
    }),
    _createVNode(_component_NavItem, {
      name: "Certificates",
      url: "certificates"
    }),
    _createVNode(_component_NavItem, {
      name: "Contact",
      url: "contact"
    })
  ]))
}