import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e3aabaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "skills" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.certificates, (certificate, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "project",
        key: index
      }, [
        _createElementVNode("a", {
          class: "certificate",
          href: certificate.link
        }, [
          _createElementVNode("img", {
            src: '/icons/companies/' + certificate.company.toLowerCase() + '.svg'
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(certificate.name), 1),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(certificate.skills, (skill, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "skill",
                  key: index
                }, _toDisplayString(skill), 1))
              }), 128))
            ])
          ])
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}