import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8ddf7c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "socialicons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocialIcon = _resolveComponent("SocialIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SocialIcon, {
      icon: "github",
      alt: "GitHub",
      url: "//github.com/LeonhardTissen"
    }),
    _createVNode(_component_SocialIcon, {
      icon: "gitlab",
      alt: "GitLab",
      url: "//gitlab.com/LeonhardTissen"
    }),
    _createVNode(_component_SocialIcon, {
      icon: "youtube",
      alt: "YouTube",
      url: "//youtube.com/@warze"
    }),
    _createVNode(_component_SocialIcon, {
      icon: "linkedin",
      alt: "LinkedIn",
      url: "//www.linkedin.com/in/leonhard-tissen/"
    }),
    _createVNode(_component_SocialIcon, {
      icon: "x",
      alt: "Twitter / X",
      url: "//twitter.com/leonhard_tissen"
    }),
    _createVNode(_component_SocialIcon, {
      icon: "mastodon",
      alt: "Mastodon",
      url: "//mastodon.social/@leonhardtissen"
    }),
    _createVNode(_component_SocialIcon, {
      icon: "website",
      alt: "Warze.org",
      url: "//warze.org"
    }),
    _createVNode(_component_SocialIcon, {
      icon: "email",
      alt: "E-mail",
      url: "mailto:contact@warze.org"
    })
  ]))
}