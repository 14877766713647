import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56b6caf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "navitem",
    id: 'nav' + $props.url,
    href: '#' + $props.url,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args)))
  }, _toDisplayString($props.name), 9, _hoisted_1))
}