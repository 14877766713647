import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "page selected",
  id: "home"
}
const _hoisted_2 = {
  class: "page selected",
  id: "goals"
}
const _hoisted_3 = {
  class: "page selected",
  id: "projects"
}
const _hoisted_4 = {
  class: "page",
  id: "certificates"
}
const _hoisted_5 = {
  class: "page selected",
  id: "contact"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderContainer = _resolveComponent("HeaderContainer")!
  const _component_LandingShow = _resolveComponent("LandingShow")!
  const _component_MyGoals = _resolveComponent("MyGoals")!
  const _component_MyProjects = _resolveComponent("MyProjects")!
  const _component_MyCertificates = _resolveComponent("MyCertificates")!
  const _component_ContactMe = _resolveComponent("ContactMe")!
  const _component_FooterContainer = _resolveComponent("FooterContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderContainer),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LandingShow)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MyGoals)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MyProjects)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MyCertificates)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ContactMe)
    ]),
    _createVNode(_component_FooterContainer)
  ], 64))
}